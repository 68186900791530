import { createEffect, createEvent } from "effector";

import { getChallengesUseCase } from "@/useCases/challenges/GetChallengesUseCase";

import { ChallengesState } from "./ChallengesState";

interface GetChallengesProps {
  vendorId: string;
  accountId: string;
}

interface GetChallengesNextPageProps extends GetChallengesProps {
  page: number;
}

export const getChallenges = createEffect({
  async handler(props: GetChallengesProps) {
    return await getChallengesUseCase({ ...props, page: 1 });
  },
});

export const getNextPageChallenges = createEffect({
  async handler(props: GetChallengesNextPageProps) {
    return await getChallengesUseCase({ ...props, page: props.page + 1 });
  },
});

export const setChallenges =
  createEvent<Partial<ChallengesState>>("setChallenges");

export const resetChallenges = createEvent("resetChallenges");
