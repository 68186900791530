import { Badge } from "@hexa-ui/components";

import { Tag as TagDomain } from "@/domain/ChallengesDomain";

import useChallengeTag from "./hooks/useChallengeTag";

export default function ChallengeTag({ tag }: { tag: TagDomain }) {
  const { Status } = Badge;

  const { label, color } = useChallengeTag({ tag });

  return <Status label={label} color={color} />;
}
