import { createStore } from "effector";

import {
  getChallenges,
  getNextPageChallenges,
  resetChallenges,
  setChallenges,
} from "./ChallengesEvents";
import { challengesInitialState, ChallengesState } from "./ChallengesState";

const ChallengesStore = createStore(challengesInitialState)
  .on(getNextPageChallenges, (state: ChallengesState) => {
    return {
      ...state,
      nextPageStatus: {
        isLoading: true,
        done: true,
        error: false,
        errorMessage: undefined,
      },
    };
  })
  .on(getNextPageChallenges.done, (state: ChallengesState, { result }) => {
    return {
      ...state,
      ...result,
      challenges: [...state.challenges, ...result.challenges],
      nextPageStatus: {
        isLoading: false,
        done: true,
        error: false,
        errorMessage: undefined,
      },
    };
  })
  .on(getNextPageChallenges.fail, (state: ChallengesState, { error }) => {
    return {
      ...state,
      nextPageStatus: {
        isLoading: false,
        done: true,
        error: true,
        errorMessage: error.message,
      },
    };
  })

  .on(getChallenges, (state: ChallengesState) => {
    return {
      ...state,
      error: false,
      done: false,
      isLoading: true,
    };
  })
  .on(getChallenges.done, (state: ChallengesState, { result }) => {
    return {
      ...state,
      ...result,
      isLoading: false,
      done: true,
      error: false,
      errorMessage: undefined,
    };
  })
  .on(getChallenges.fail, (state: ChallengesState, { error }) => {
    return {
      ...state,
      isLoading: false,
      done: true,
      error: true,
      errorMessage: error.message,
    };
  })

  .on(setChallenges, (state, update) => {
    return {
      ...state,
      ...update,
    };
  })
  .on(resetChallenges, () => {
    return challengesInitialState;
  });

export default ChallengesStore;
