import {
  PaginationSection,
  PaginationSectionType,
} from "@bees-grow-shared/business-components";
import { ResponsiveGrid } from "@bees-grow-shared/components";
import { Card } from "@hexa-ui/components";

import * as Styled from "./ChallengeGrid.styles";
import ChallengeCard from "./components/challengeCard/ChallengeCard";
import { useChallengeGrid } from "./hooks/useChallengeGrid";

export default function ChallengeGrid() {
  const {
    isLoading,
    challenges,
    scrollPosition,
    hasNextPage,
    isNextPageStatusLoading,
    isNextPageStatusError,
    isNextPageStatusDone,
    getNextPage,
    setScrollPosition,
  } = useChallengeGrid();

  if (isLoading) return <LoadingBehavior />;

  return (
    <div data-testid="challenge-grid">
      <ResponsiveGrid>
        {challenges?.map((challenge) => {
          return <ChallengeCard key={challenge.id} challenge={challenge} />;
        })}
      </ResponsiveGrid>
      <PaginationSection
        isLoading={isNextPageStatusLoading}
        error={isNextPageStatusError}
        done={isNextPageStatusDone}
        scrollPosition={scrollPosition}
        pagination={{ hasNextPage, getNextPage }}
        debounce={setScrollPosition}
        type={PaginationSectionType.POINTS_BASED_OFFERS}
      />
    </div>
  );
}

export function LoadingBehavior() {
  const LoadingBehaviorChallengeCard = () => (
    <Card elevated="minimal" border="small">
      <Styled.LoadingChallengeCardImage variant="image" />
      <Styled.LoadingChallengeCardContainer>
        <Styled.LoadingChallengeCardContainerTitle>
          <Styled.LoadingChallengeCardTitleTop />
          <Styled.LoadingChallengeCardTitleBottom />
        </Styled.LoadingChallengeCardContainerTitle>
        <Styled.LoadingChallengeCardContainerTags>
          <Styled.LoadingChallengeCardTagFirst />
          <Styled.LoadingChallengeCardTagSecond />
        </Styled.LoadingChallengeCardContainerTags>
      </Styled.LoadingChallengeCardContainer>
    </Card>
  );

  return (
    <ResponsiveGrid data-testid="challenge-cards-loading">
      {[...Array(4)].map((_, i) => (
        <LoadingBehaviorChallengeCard key={i} />
      ))}
    </ResponsiveGrid>
  );
}
