import { Challenges } from "@/domain/ChallengesDomain";
import { ChallengesResponse } from "@/services/challenges/models/GetChallengesResponse";

export const getChallengesConverter = (
  response: ChallengesResponse,
): Challenges => {
  return {
    challenges: response.content,
    page: response.page,
    pageSize: response.pageSize,
    hasNextPage: response.hasNextPage,
  } as Challenges;
};
