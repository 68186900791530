import { StatusControlState } from "@bees-grow-shared/services";

import { Challenge, Challenges } from "@/domain/ChallengesDomain";

export interface ChallengesState extends Challenges {
  challenges: Challenge[];
  isLoading: boolean;
  error: boolean;
  errorMessage: string;
  done: boolean;
  nextPageStatus: StatusControlState;
}

export const challengesInitialState: ChallengesState = {
  challenges: [],
  isLoading: false,
  error: false,
  errorMessage: "",
  done: false,
  page: 1,
  pageSize: 10,
  hasNextPage: false,
  scrollPosition: 0,
  nextPageStatus: {
    isLoading: false,
    error: false,
    done: false,
  },
};
