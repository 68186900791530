import { useCallback, useEffect, useMemo } from "react";

import {
  accountSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";

import {
  getChallenges,
  getNextPageChallenges,
  setChallenges,
} from "@/stores/challenges/ChallengesEvents";
import ChallengesStore from "@/stores/challenges/ChallengesStore";

export function useChallengeGrid() {
  const accountService = accountSharedService();
  const {
    vendorId,
    accountId,
    isLoading: isAccountLoading,
  } = useSharedUnit(accountService);
  const {
    isLoading: isChallengesLoading,
    challenges,
    scrollPosition,
    hasNextPage,
    page,
    nextPageStatus: {
      isLoading: isNextPageStatusLoading,
      error: isNextPageStatusError,
      done: isNextPageStatusDone,
    },
  } = useUnit(ChallengesStore);

  const isLoading = useMemo(
    () => isAccountLoading || isChallengesLoading,
    [isAccountLoading, isChallengesLoading],
  );

  useEffect(() => {
    if (accountId && vendorId) {
      getChallenges({ vendorId, accountId });
    }
  }, [accountId, vendorId]);

  const getNextPage = useCallback(() => {
    getNextPageChallenges({ vendorId, accountId, page });
  }, [accountId, page, vendorId]);

  const setScrollPosition = useCallback(() => {
    setChallenges({ scrollPosition: window.scrollY });
  }, []);

  return {
    isLoading,
    challenges,
    scrollPosition,
    hasNextPage,
    isNextPageStatusLoading,
    isNextPageStatusError,
    isNextPageStatusDone,
    getNextPage,
    setScrollPosition,
  };
}
