import { useIntl } from "react-intl";

import { sanitize } from "dompurify";

import { Challenge, Tag } from "@/domain/ChallengesDomain";

export default function useDrawerBody({ challenge }: { challenge: Challenge }) {
  const { formatMessage } = useIntl();

  let points: Tag;
  let timeLeft: Tag;

  for (let i = 0; i < challenge.tags.length; i++) {
    const tag = challenge.tags[i];

    if (tag.type === "POINTS") {
      points = tag;
    } else if (tag.type === "REMAINING_DAYS") {
      timeLeft = tag;
    } else if (points && timeLeft) {
      break;
    }
  }

  const imgUrl = sanitize(challenge.imgUrl);
  const badPhotoSample = sanitize(challenge.badPhotoSample);
  const goodPhotoSample = sanitize(challenge.goodPhotoSample);

  const isTakePhoto = challenge.executionMethod === "TAKE_PHOTO";

  const alertLabel = formatMessage({
    id: "ClubB.Content.PointsBasedOffers.ChallengeCards.DrawerBody.ALERT_MESSAGE",
  });

  const rewardLabel = formatMessage({
    id: "ClubB.Content.PointsBasedOffers.ChallengeCards.DrawerBody.REWARD",
  });

  const timeLeftLabel = formatMessage({
    id: "ClubB.Content.PointsBasedOffers.ChallengeCards.DrawerBody.TIME_LEFT",
  });

  const statusTitleLabel = formatMessage({
    id: "ClubB.Content.PointsBasedOffers.ChallengeCards.DrawerBody.STATUS",
  });

  const statusValueLabel = formatMessage({
    id: `ClubB.Content.PointsBasedOffers.ChallengeCards.DrawerBody.${challenge.status}`,
  });

  const typeLabel = formatMessage({
    id: "ClubB.Content.PointsBasedOffers.ChallengeCards.DrawerBody.TYPE",
  });

  const executionMethodLabel = formatMessage({
    id: `ClubB.Content.PointsBasedOffers.ChallengeCards.DrawerBody.${challenge.executionMethod}`,
  });

  return {
    points,
    timeLeft,
    isTakePhoto,
    imgUrl,
    badPhotoSample,
    goodPhotoSample,
    alertLabel,
    rewardLabel,
    timeLeftLabel,
    statusTitleLabel,
    statusValueLabel,
    typeLabel,
    executionMethodLabel,
  };
}
