import { tokens } from "@bees-grow-shared/theme";
import { SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const LoadingChallengeCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${tokens.measure.space["spacing-4"]};
  margin: 0.75rem;
  margin-bottom: ${tokens.measure.space["spacing-4"]};
`;

export const LoadingChallengeCardContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-05"]};
`;

export const LoadingChallengeCardContainerTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.measure.space["spacing-2"]};
`;

export const LoadingChallengeCardImage = styled(SkeletonLoader)`
  width: 100%;
  height: 9.1875rem;
`;

export const LoadingChallengeCardTitleTop = styled(SkeletonLoader)`
  width: calc(100% - 24px);
  height: 1.25rem;
`;

export const LoadingChallengeCardTitleBottom = styled(SkeletonLoader)`
  width: 5rem;
  height: 1.25rem;
`;

export const LoadingChallengeCardTagFirst = styled(SkeletonLoader)`
  width: 2.9375rem;
  height: 1.5rem;
`;

export const LoadingChallengeCardTagSecond = styled(SkeletonLoader)`
  width: 5.5625rem;
  height: 1.5rem;
`;
