import { Challenges } from "@/domain/ChallengesDomain";
import { getChallenges } from "@/services/challenges/getChallengesService";

import { getChallengesConverter } from "./converters/GetChallengesConverter";

interface GetChallengesUseCaseProps {
  vendorId: string;
  accountId: string;
  page: number;
}

export async function getChallengesUseCase({
  vendorId,
  accountId,
  page,
}: GetChallengesUseCaseProps): Promise<Challenges> {
  const response = await getChallenges({
    query: { accountId, page, pageSize: 10 },
    headers: { vendorId },
  });

  return getChallengesConverter(response);
}
