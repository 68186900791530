import { Checkmark2, XCircle } from "@hexa-ui/icons";

import { Challenge } from "@/domain/ChallengesDomain";

import ProgressBar from "../progressBar/ProgressBar";
import ChallengeTag from "../tags/components/challengeTag/ChallengeTag";
import * as Styled from "./DrawerBody.styles";
import useDrawerBody from "./hooks/useDrawerBody";

function DrawerBody({ challenge }: { challenge: Challenge }) {
  const {
    points,
    timeLeft,
    imgUrl,
    badPhotoSample,
    goodPhotoSample,
    isTakePhoto,
    alertLabel,
    rewardLabel,
    timeLeftLabel,
    statusTitleLabel,
    statusValueLabel,
    typeLabel,
    executionMethodLabel,
  } = useDrawerBody({
    challenge,
  });

  return (
    <Styled.Container>
      {!isTakePhoto && (
        <Styled.challengeAlert message={alertLabel} type="warning" />
      )}
      <Styled.Table>
        <Styled.Row>
          <Styled.Label weight="semibold">{rewardLabel}</Styled.Label>
          <ChallengeTag tag={points} />
        </Styled.Row>
        <Styled.Row>
          <Styled.Label weight="semibold">{timeLeftLabel}</Styled.Label>
          <ChallengeTag tag={timeLeft} />
        </Styled.Row>
        <Styled.Row>
          <Styled.Label weight="semibold">{statusTitleLabel}</Styled.Label>
          <Styled.Label weight="normal">{statusValueLabel}</Styled.Label>
        </Styled.Row>
        <Styled.Row>
          <Styled.Label weight="semibold">{typeLabel}</Styled.Label>
          <Styled.Label weight="normal">{executionMethodLabel}</Styled.Label>
        </Styled.Row>
      </Styled.Table>
      {isTakePhoto ? (
        <Styled.ImageExampleContainer>
          <Styled.ImageExample src={goodPhotoSample} />
          <Styled.ImageExample src={badPhotoSample} />
          <Styled.CorrectPhotoIndicator>
            <Checkmark2 />
          </Styled.CorrectPhotoIndicator>
          <Styled.WrongPhotoIndicator>
            <XCircle />
          </Styled.WrongPhotoIndicator>
        </Styled.ImageExampleContainer>
      ) : (
        <Styled.ChallengeDetailsImg src={imgUrl} alt="Challenge details" />
      )}
      <Styled.ChallengeDescription>
        {challenge.description}
      </Styled.ChallengeDescription>
      <ProgressBar
        executionMethod={challenge.executionMethod}
        quantity={challenge.quantity}
        quantityLeft={challenge.quantityLeft}
        quantityPurchased={challenge.quantityPurchased}
      />
    </Styled.Container>
  );
}

export default DrawerBody;
