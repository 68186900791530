import { axiosInstance } from "@bees-grow-shared/services";

import { CHALLENGES_ENDPOINT } from "./endpoints";
import {
  GetChallengesRequestHeaders,
  GetChallengesRequestQuery,
} from "./models/GetChallengesRequest";
import { ChallengesResponse } from "./models/GetChallengesResponse";

interface getChallengesProps {
  query: GetChallengesRequestQuery;
  headers: GetChallengesRequestHeaders;
}

export async function getChallenges({
  query,
  headers,
}: getChallengesProps): Promise<ChallengesResponse> {
  const { data } = await axiosInstance.get(
    CHALLENGES_ENDPOINT.GET_CHALLENGES(query),
    { headers: { ...headers } },
  );

  return data;
}
