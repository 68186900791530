import { constructEndpoint } from "@bees-grow-shared/services";

import { MICROSERVICES } from "../MicroServices";

enum ENDPOINTS {
  GET_CHALLENGES = "/accounts/{accountId}/challenges",
}

export interface GetChallengesParams {
  accountId: string;
  page?: number;
  pageSize?: number;
}

export const CHALLENGES_ENDPOINT = {
  GET_CHALLENGES: ({ accountId, page, pageSize }: GetChallengesParams) =>
    constructEndpoint({
      service: MICROSERVICES.BEES_GROW_ORDER_TAKING_BFF,
      endpoint: `${ENDPOINTS.GET_CHALLENGES}`,
      queryParams: { page, pageSize },
      pathParams: { accountId },
    }),
};
